import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { useCallback, useMemo } from 'react';
import { AreaClosed, LinePath } from '@visx/shape';
import { curveLinear } from '@visx/curve';
import { scaleTime, scaleLinear, } from '@visx/scale';
import { extent } from '@visx/vendor/d3-array';
import { format } from 'date-fns';
import { localPoint } from '@visx/event';
import { LinearGradient } from '@visx/gradient';
import { AxisBottom, AxisRight } from '@visx/axis';
import { useAppSelector } from '@app/store/Hooks';
import { getLocale } from '@shared/lib/date-time';
import { formatNumber, formatUSD } from '@shared/lib/numbers';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import { GRAPH_CURVE_HEIGHT, GRAPH_CURVE_WIDTH, GRAPH_HEIGHT, GRAPH_WIDTH, GraphDetails, GraphDetailsPeriod, GraphDetailsTitle, GraphDetailsUnit, IncomeGraphContainer } from '../graphs-shared';
const IncomeCurveContainer = styled(IncomeGraphContainer)(() => ({
    position: 'relative',
}));
const Svg = styled.svg(() => ({
    width: GRAPH_WIDTH,
    height: GRAPH_HEIGHT,
    overflow: 'visible',
}));
const CurveSvgContainer = styled.svg();
const HoverRect = styled.rect(() => ({
    fill: 'transparent',
    width: GRAPH_CURVE_WIDTH,
    height: GRAPH_CURVE_HEIGHT,
    overflow: 'hidden',
}));
const TooltipLine = styled.path(props => ({
    visibility: props.isVisible ? 'visible' : 'hidden',
    stroke: props.theme.palette.info.main,
    strokeDasharray: '4 4',
    transform: props.xCoord ? `translateX(${props.xCoord}px)` : '',
}));
const TooltipDotCircle = styled.circle(props => ({
    fill: '#fff',
    stroke: props.type === 'income' ? props.theme.palette.info.main : props.theme.palette.warning.main,
    strokeWidth: 2,
    visibility: props.isVisible ? 'visible' : 'hidden',
    transform: `translate(${props.x || 0}px, ${props.y || 0}px)`,
}));
const TooltipStyled = styled(TooltipWithBounds)(props => ({
    '&.visx-tooltip': {
        display: 'flex',
        gap: props.theme.spacing_sizes.xs * 0.5,
        flexDirection: 'column',
        backgroundColor: props.theme.palette.text.primary,
        color: '#fff',
        pointerEvents: 'none',
        fontSize: 14,
        letterSpacing: '-0.084px',
        lineHeight: '20px',
    },
}));
const tooltipStyles = {
    position: 'relative',
    top: 0,
    left: 0,
    transform: 'translate(0, 0)',
    backgroundColor: 'transparent',
};
const XAxisContainer = styled.g(props => ({
    transform: `translate(0, calc(${GRAPH_CURVE_HEIGHT + 3}px))`,
    '& .SubscriptionConversionCurve__XAxis text': {
        fontSize: 12,
        fontWeight: 400,
        letterSpacing: '-0.072px',
        fill: '#ABB4BB',
    },
    '& .SubscriptionConversionCurve__XAxis line': {
        strokeDasharray: '0.25 1',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        stroke: props.theme.palette.text.secondary,
        strokeWidth: 1,
    },
    '& .visx-axis-tick': {
        x: 0,
    },
}));
const YAxisContainer = styled.g(() => ({
    transform: `translate(${GRAPH_CURVE_WIDTH - 8}px, 0)`,
    '& .SubscriptionConversionCurve__YAxis text': {
        fontSize: 12,
        fontWeight: 400,
        fill: '#ABB4BB',
    },
}));
const TotalIncomeGraph = ({ data, defaultRange, isLoading = false, }) => {
    const { t } = useTranslation();
    const { language } = useAppSelector((state) => state.app);
    const theme = useTheme();
    const { tooltipOpen, tooltipData, hideTooltip, showTooltip, } = useTooltip({});
    const { curveDots, lastDateIncome, lastDateRefund } = useMemo(() => {
        const result = data.reduce((acc, val, index) => {
            const key = `${new Date(val[0]).getFullYear()}-${new Date(val[0]).getMonth()}-${new Date(val[0]).getDate()}`;
            return (Object.assign(Object.assign({}, acc), { [key]: {
                    income: val[1],
                    refunds: val[2],
                    order: index,
                } }));
        }, {});
        return {
            curveDots: result,
            lastDateIncome: data.length ? data[data.length - 1][1] : 0,
            lastDateRefund: data.length ? data[data.length - 1][2] : 0,
        };
    }, [data]);
    const scaleXFormat = (date) => format(date, 'dd MMM', { locale: getLocale(language || 'en') });
    const scaleYFormat = (amount) => formatNumber(amount, { compactNotation: true, maximumFractionDigits: amount !== 0 && amount % 1000 !== 0 ? 2 : 0 });
    const getX = (pointCoords) => pointCoords === null || pointCoords === void 0 ? void 0 : pointCoords[0];
    const getClientAssetsValue = (pointCoords) => pointCoords === null || pointCoords === void 0 ? void 0 : pointCoords[1];
    const getPortfolioAssetsValue = (pointCoords) => pointCoords === null || pointCoords === void 0 ? void 0 : pointCoords[2];
    const scaleX = useMemo(() => {
        if (data.length) {
            return scaleTime({
                domain: extent(data, getX),
                range: [0, GRAPH_CURVE_WIDTH],
                nice: false,
            });
        }
        return scaleTime({
            domain: extent(defaultRange, getX),
            range: [0, GRAPH_CURVE_WIDTH],
            nice: false,
        });
    }, [data, defaultRange]);
    const scaleY = useMemo(() => {
        if (data.length) {
            return scaleLinear({
                domain: extent(data, getClientAssetsValue),
                range: [GRAPH_CURVE_HEIGHT, 0],
                nice: true,
            });
        }
        return scaleLinear({
            domain: [0, 100],
            range: [GRAPH_CURVE_HEIGHT, 0],
            nice: true,
        });
    }, [data]);
    const handleTooltipPosition = useCallback((event) => {
        if (data.length && !isLoading) {
            const { x } = localPoint(event) || { x: 0 };
            const x0 = scaleX.invert(x);
            const key = `${new Date(x0).getFullYear()}-${new Date(x0).getMonth()}-${new Date(x0).getDate()}`;
            const index = curveDots[key];
            const d0 = data[(index === null || index === void 0 ? void 0 : index.order) > 0 ? index.order : 0];
            const d1 = data[index === null || index === void 0 ? void 0 : index.order];
            let d = d0;
            if (d1 && getX(d1)) {
                d = x0.valueOf() - getX(d0).valueOf() <
                    getX(d1).valueOf() - x0.valueOf() ?
                    d1 : d0;
            }
            showTooltip({
                tooltipData: {
                    date: `${(d === null || d === void 0 ? void 0 : d.length) ? format(new Date(d[0]), 'MMM dd, yyyy', { locale: getLocale(language || 'en') }) : ''}`,
                    income: d ? d[1] : 0,
                    refunds: d ? d[2] : 0,
                    xCoord: d ? scaleX(getX(d)) : 0,
                    incomeYCoord: d ? scaleY(getClientAssetsValue(d)) : 0,
                    refundYCoord: d ? scaleY(getPortfolioAssetsValue(d)) : 0,
                },
            });
        }
        else if (!isLoading) {
            // when data is empty we`d always show cursor at middle of the graph
            const d = defaultRange[1];
            showTooltip({
                tooltipData: {
                    date: `${(d === null || d === void 0 ? void 0 : d.length) ? format(new Date(d[0]), 'MMM dd, yyyy', { locale: getLocale(language || 'en') }) : ''}`,
                    income: 0,
                    refunds: 0,
                    xCoord: d ? scaleX(getX(d)) : 0,
                    incomeYCoord: d ? scaleY(getClientAssetsValue(d)) : 0,
                    refundYCoord: 0,
                },
            });
        }
    }, [showTooltip, isLoading, scaleX, scaleY, data, language, curveDots]);
    return (_jsxs(IncomeCurveContainer, { children: [_jsxs(TooltipStyled, { style: Object.assign({}, tooltipStyles), children: [_jsx(WithSkeleton, { isLoading: isLoading, width: 182, height: 18, children: _jsx(GraphDetailsTitle, { children: Trans({
                                i18nKey: 'balance.trader_balance.management_income.income_graph.title',
                                values: {
                                    amount: formatUSD(typeof (tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.income) !== 'undefined' ?
                                        tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.income : lastDateIncome),
                                },
                            }) }) }), _jsx(WithSkeleton, { isLoading: isLoading, width: 146, height: 17, children: _jsx(GraphDetails, { children: _jsx(GraphDetailsUnit, { children: Trans({
                                    i18nKey: 'balance.trader_balance.management_income.income_graph.total_refunds',
                                    values: {
                                        amount: formatUSD(typeof (tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.refunds) !== 'undefined' ?
                                            tooltipData.refunds : lastDateRefund),
                                    },
                                }) }) }) }), _jsx(WithSkeleton, { isLoading: isLoading, width: 59, height: 18, children: _jsx(GraphDetailsPeriod, { children: (tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.date) || 'Past 30 d.' }) })] }), _jsxs(Svg, { children: [_jsxs(CurveSvgContainer, { children: [_jsx(LinearGradient, { id: 'income-background-gradient', from: theme.palette.info.main, fromOpacity: 0.2, fromOffset: '0%', to: theme.palette.info.main, toOffset: 100, toOpacity: 0 }), _jsx(LinearGradient, { id: 'refunds-background-gradient', from: theme.palette.warning.main, fromOpacity: 0.5, fromOffset: '0%', to: theme.palette.warning.main, toOffset: 100, toOpacity: 0 }), !isLoading &&
                                _jsx(LinePath, { x: (d) => scaleX(getX(d)), y: (d) => scaleY(getClientAssetsValue(d)), curve: curveLinear, data: (data === null || data === void 0 ? void 0 : data.length) ? data : defaultRange, stroke: theme.palette.primary.main, strokeWidth: (data === null || data === void 0 ? void 0 : data.length) ? 1 : 2 }), !isLoading &&
                                _jsx(AreaClosed, { data: data, x: (d) => scaleX(getX(d)), y: (d) => scaleY(getClientAssetsValue(d)), yScale: scaleY, fill: 'url(#income-background-gradient)' }), !isLoading && data.length &&
                                _jsx(LinePath, { x: (d) => scaleX(getX(d)), y: (d) => scaleY(getPortfolioAssetsValue(d)), curve: curveLinear, data: (data === null || data === void 0 ? void 0 : data.length) ? data : defaultRange, stroke: (data === null || data === void 0 ? void 0 : data.length) ? theme.palette.warning.main : '#ABB4BB', strokeWidth: (data === null || data === void 0 ? void 0 : data.length) ? 1 : 2 }), !isLoading &&
                                _jsx(AreaClosed, { data: data, x: (d) => scaleX(getX(d)), y: (d) => scaleY(getPortfolioAssetsValue(d)), yScale: scaleY, fill: 'url(#refunds-background-gradient)' }), _jsx(TooltipLine, { isVisible: tooltipOpen, xCoord: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.xCoord, d: `M0 0 L0 ${GRAPH_CURVE_HEIGHT}` }), _jsx(TooltipDotCircle, { isVisible: tooltipOpen, x: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.xCoord, y: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.incomeYCoord, r: 3, type: 'income' }), _jsx(TooltipDotCircle, { isVisible: tooltipOpen && data.length > 0, x: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.xCoord, y: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.refundYCoord, r: 3, type: 'refund' }), _jsx(HoverRect, { onTouchStart: handleTooltipPosition, onTouchMove: handleTooltipPosition, onMouseMove: handleTooltipPosition, onMouseLeave: hideTooltip })] }), !isLoading &&
                        _jsx(YAxisContainer, { children: _jsx(AxisRight, { axisClassName: 'SubscriptionConversionCurve__YAxis', scale: scaleY, tickFormat: (val) => scaleYFormat(val), numTicks: 2, hideTicks: true, hideAxisLine: true }) }), !isLoading &&
                        _jsx(XAxisContainer, { children: _jsx(AxisBottom, { axisClassName: 'SubscriptionConversionCurve__XAxis', scale: scaleX, tickFormat: (date) => scaleXFormat(date), numTicks: 1, hideTicks: true }) })] })] }));
};
export default TotalIncomeGraph;
